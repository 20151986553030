<template>
  <div
    :infinite-scroll-delay="300"
    :infinite-scroll-immediate="false"
    :infinite-scroll-distance="220"
    v-infinite-scroll="load"
  >
    <Pos :content="$route.params.name" />
    <div class="tag-info">
      <img :src="tagDetail.tag_icon" class="icon" />

      <div class="inner">
        <h1 class="title">
          {{ tagDetail.tag_name }}
        </h1>
        <p class="content">
          {{ tagDetail.tag_content }}
        </p>
      </div>

      <div>
        <FollowBtn
          v-if="tagDetail.tag_id"
          :target-id="tagDetail.tag_id"
          :follow-type="1"
        />
      </div>
    </div>
    <CardItem :data-list="dataList" />
  </div>
</template>

<script>
import CardItem from "@/components/CardItem";
import Pos from "@/components/Pos";
import FollowBtn from "@/components/FollowBtn";
import { tagInfoById } from "@/api/tag";
import { cardListByTag } from "@/api/card";

export default {
  data() {
    return {
      tagDetail: {},
      dataList: [],
      isAll: false,
      page: 1,
    };
  },
  components: {
    CardItem,
    Pos,
    FollowBtn,
  },
  created() {
    this.__loadTagInfoById();
    this.__loadListData();
  },
  methods: {
    // tag详情
    async __loadTagInfoById() {
      const { data, code, msg } = await tagInfoById({
        tag_id: this.$route.params.id,
      });
      if (code === 200) {
        this.tagDetail = data;
      } else {
        this.$message.error(msg);
      }
    },
    // 数据列表
    async __loadListData() {
      const { code, data } = await cardListByTag({
        page: this.page,
        limit: 5,
        tag_id: this.$route.params.id,
      });

      if (code === 200 && data.length > 0) {
        this.dataList = [...this.dataList, ...data];
        this.isAll = false;
      } else {
        this.isAll = true;
      }
    },
    load() {
      if (!this.isAll) {
        ++this.page;
        this.__loadListData();
      }
    },
  },
  watch: {
    $route: {
      handler() {
        window.location.reload();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tag-info {
  border-bottom: 8px solid #eff3f4;
  padding: 16px;

  display: flex;

  .icon {
    display: block;
    width: 96px;
    height: 96px;
    background-color: sandybrown;
    border-radius: 50%;
    margin-right: 20px;
  }

  .inner {
    width: 80%;
    h1.title {
      line-height: 28px;
      font-size: 23px;
      padding-top: 4px;
      color: rgb(15, 20, 25);
    }

    p.content {
      font-weight: 400;
      font-size: 15px;
      color: rgb(15, 20, 25);
      line-height: 20px;
    }
  }
}
</style>